import {
    UPDATE_CONFIG,
    FETCH_CONFIG,
    FETCH_CONFIG_SUCCESS,
    FETCH_CONFIG_ERROR,
    UPDATE_CONFIG_SUCCESS,
    UPDATE_CONFIG_ERROR,
    CONFIG_ERROR_REMOVE
} from '@redux/types'

import _ from 'lodash'

const initialState = {
    config: {

    }
};


export default (state = initialState, action) => {
    let list;
    switch (action.type) {

        case FETCH_CONFIG:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_CONFIG_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_CONFIG_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case UPDATE_CONFIG:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_CONFIG_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_CONFIG_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        
        
        case CONFIG_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        default:
            return state;
    }
};


