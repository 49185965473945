import {
    DELETE_BANNER,
    DELETE_BANNER_SUCCESS,
    DELETE_BANNER_ERROR,
    UPDATE_BANNER,
    FETCH_BANNER,
    FETCH_BANNER_SUCCESS,
    FETCH_BANNER_ERROR,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER_ERROR,
    ADD_BANNER,
    ADD_BANNER_SUCCESS,
    ADD_BANNER_ERROR,
    BANNER_ERROR_REMOVE
} from '@redux/types'

import _ from 'lodash'
import { ADD_CUSTOMERS_ERROR } from '../types';

const initialState = {
    type: null,
    error: null,
    list: {},
    sortBy: 'created_at',
    sortDir: -1,
    filters: {}
};


export default (state = initialState, action) => {
    let list;
    switch (action.type) {

        case FETCH_BANNER:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_BANNER_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_BANNER_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case UPDATE_BANNER:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_BANNER_SUCCESS:
            list = _.cloneDeep(state.list);
            let index = _.findIndex(list.data, (item) => {
                return item._id == action.payload._id
            })
            list.data[index] = action.payload
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case UPDATE_BANNER_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case ADD_BANNER:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case ADD_BANNER_SUCCESS:
            list = _.cloneDeep(state.list);
            list.data.unshift(action.payload);
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case ADD_BANNER_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case DELETE_BANNER:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case DELETE_BANNER_SUCCESS:
            list = _.cloneDeep(state.list);
            _.remove(list.data, (item) => {
                return item._id == action.payload._id
            })

            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case DELETE_BANNER_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case BANNER_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        default:
            return state;
    }
};


