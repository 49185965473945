import React from 'react'
import { Route, Redirect } from "react-router-dom";
import StorageService from '@utils/StorageService'

export default function PrivateRoute({ component: Component, ...rest }) {
    let token = StorageService.get('token')

    // console.log('Private Route(user): ', token, rest)

    return (
        <Route
            {...rest}
            render={props =>
                token ?
                    (Component?<Component {...props} />:rest.render(props))
                    : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location.state }
                        }}
                    />
                )
            }
        />
    );
}
