import {
    FETCH_PROMO_CODES,
    FETCH_PROMO_CODES_SUCCESS,
    FETCH_PROMO_CODES_ERROR,
    UPDATE_PROMO_CODES,
    UPDATE_PROMO_CODES_SUCCESS,
    UPDATE_PROMO_CODES_ERROR,
    ADD_PROMO_CODES,
    ADD_PROMO_CODES_SUCCESS,
    ADD_PROMO_CODES_ERROR,
    DELETE_PROMO_CODES,
    DELETE_PROMO_CODES_SUCCESS,
    DELETE_PROMO_CODES_ERROR,
    PROMO_CODES_ERROR_REMOVE
} from '@redux/types'

import _ from 'lodash'

const initialState = {
    type: null,
    error: null,
    list: {},
    sortBy: 'created_at',
    sortDir: -1,
    filters: {}
};

export default (state = initialState, action) => {
    let list;
    switch (action.type) {

        case FETCH_PROMO_CODES:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_PROMO_CODES_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_PROMO_CODES_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case UPDATE_PROMO_CODES:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_PROMO_CODES_SUCCESS:
            list = _.cloneDeep(state.list);
            let index = _.findIndex(list.data, (item) => {
                return item._id == action.payload._id
            })
            list.data[index] = action.payload
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case UPDATE_PROMO_CODES_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case ADD_PROMO_CODES:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case ADD_PROMO_CODES_SUCCESS:
            list = _.cloneDeep(state.list);
            list.data.unshift(action.payload);
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case ADD_PROMO_CODES_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case DELETE_PROMO_CODES:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case DELETE_PROMO_CODES_SUCCESS:
            list = _.cloneDeep(state.list);
            _.remove(list.data, (item) => {
                return item._id == action.payload._id
            })

            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case DELETE_PROMO_CODES_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case PROMO_CODES_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        default:
            return state;
    }
};
