import ApiRequest from '@utils/ApiRequest'
import storageService from '@utils/StorageService'

import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR

} from '@redux/types'


const initialState = {
    token: storageService.get('token'),
    error: null
}

export function resetPasswordAction(dispatch, data) {

    dispatch({
        type: RESET_PASSWORD,
        error: null
    })

    ApiRequest.post('password-reset', data)
        .then((response) => {
            if(response.error || response.errors)
            {
                dispatch({
                    type: RESET_PASSWORD_ERROR,
                    error: response.errors
                })
            }else{
                dispatch({
                    type: RESET_PASSWORD_SUCCESS
                })
            }
        })
}

export function registerAction(dispatch, data) {
    dispatch({
        type: REGISTER,
        error: null
    })
    ApiRequest.post('register', data)
        .then((response) => {
            if(response.error || response.errors)
            {
                dispatch({
                    type: REGISTER_ERROR,
                    error: response.errors
                })
            }else{
                // AsyncStorage.setItem('token', response.token)
                dispatch({
                    type: REGISTER_SUCCESS,
                    token: response.token
                })
            }
        })
}

export function logoutAction(dispatch) {
    dispatch({
        type: LOGOUT,
        error: null
    })
    ApiRequest.post('logout')
        .then((response) => {

            if(response.error || response.errors)
            {
                dispatch({
                    type: LOGOUT_ERROR,
                    error: response.hint?response.hint:response.message
                })
            }else{
                //AsyncStorage.setItem('token', null)
                dispatch({
                    type: LOGOUT_SUCCESS,
                    token: null
                })
            }
    })
}

export function forgotPasswordAction(dispatch, data) {
    dispatch({
        type: FORGOT_PASSWORD,
        error: null
    })
    ApiRequest.post('forgot-password', data)
        .then((response) => {

            if(response.error || response.errors)
            {
                dispatch({
                    type: FORGOT_PASSWORD_ERROR,
                    error: response.errors
                })
            }else{
                dispatch({
                    type: FORGOT_PASSWORD_SUCCESS,
                    error: null
                })
            }
        })
}

export function setErrosAction(dispatch, data) {
    dispatch(data)
}

export function userApp(state = initialState, action) {
    switch (action.type)
    {
        case RESET_PASSWORD:
            return {...state, ...action}
        break;
        case RESET_PASSWORD_SUCCESS:
            return {...state, ...action}
        break;
        case RESET_PASSWORD_ERROR:
            return {...state, ...action}
        break;
        case FORGOT_PASSWORD:
            return {...state, ...action}
        break;
        case FORGOT_PASSWORD_SUCCESS:
            return {...state, ...action}
        break;
        case FORGOT_PASSWORD_ERROR:
            return {...state, ...action}
        break;
        case LOGIN:
            return {...state, ...action}
        break;
        case LOGIN_SUCCESS:
            return {...state, ...action}
        break;
        case LOGIN_ERROR:
            return {...state, ...action}
        break;
        case LOGOUT:
            return {...state, ...action}
        break;
        case LOGOUT_SUCCESS:
            return {...state, ...action}
        break;
        case REGISTER:
            return {...state, ...action}
        break;
        case REGISTER_SUCCESS:
            return {...state, ...action}
        break;
        case REGISTER_ERROR:
            return {...state, ...action}
        break;
        default:
            return state
    }
}