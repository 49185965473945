import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_ERROR,
    UPDATE_USERS,
    UPDATE_USERS_SUCCESS,
    UPDATE_USERS_ERROR,
    ADD_USERS,
    ADD_USERS_SUCCESS,
    ADD_USERS_ERROR,
    DELETE_USERS,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_ERROR,
    USERS_ERROR_REMOVE
} from '@redux/types'

const initialState = {
    type: null,
    error: null,
    list: {},
};

export default (state = initialState, action) => {
    switch (action.type) {

        case FETCH_USERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_USERS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case UPDATE_USERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_USERS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_USERS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case ADD_USERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case ADD_USERS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case ADD_USERS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case DELETE_USERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case DELETE_USERS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case DELETE_USERS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case USERS_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        default:
            return state;
    }
};
