import {
    FETCH_WALLETS,
    FETCH_WALLETS_SUCCESS,
    FETCH_WALLETS_ERROR,
    UPDATE_WALLETS,
    UPDATE_WALLETS_SUCCESS,
    UPDATE_WALLETS_ERROR,
    CREATE_WALLETS,
    CREATE_WALLETS_SUCCESS,
    CREATE_WALLETS_ERROR,
    WALLETS_ERROR_REMOVE,
    WALLETS_UPDATE_COLUMNS
} from '@redux/types'

import _ from 'lodash'

const initialState = {
    type: null,
    error: null,
    list: {},
    sortBy: 'created_at',
    sortDir: -1,
    filters: {},
};

export default (state = initialState, action) => {
    let list;
    switch (action.type) {

        case FETCH_WALLETS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_WALLETS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_WALLETS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case UPDATE_WALLETS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_WALLETS_SUCCESS:
            list = _.cloneDeep(state.list);
            let index = _.findIndex(list.data, (item) => {
                return item._id == action.payload._id
            })
            list.data[index] = action.payload
            return {
                ...state,
                ...action,
                list: list,
                error: null,
            }
            break;
        case UPDATE_WALLETS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
            case CREATE_WALLETS:
                return {
                    ...state,
                    ...action,
                    error: null,
                }
                break;
            case CREATE_WALLETS_SUCCESS:
                list = _.cloneDeep(state.list);
                list.data.unshift(action.payload);
                return {
                    ...state,
                    ...action,
                    list: list,
                    error: null,
                }
                break;
            case CREATE_WALLETS_ERROR:
                return {
                    ...state,
                    ...action,
                }
                break;    
        case WALLETS_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        case WALLETS_UPDATE_COLUMNS:
            return {
                ...state,
                columns: action.payload
            }
        default:
            return state;
    }
};
