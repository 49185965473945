/**
 * Created by admin1 on 26/10/18.
 */
import {combineReducers} from 'redux'
import {userApp} from './UserReducer'
import usersTblApp from './UsersTblReducer'
import PromoCodes from './PromoCodesReducer'
import CustomersReducer from './CustomersReducer'
import ServicesReducer from './ServicesReducer'
import OrdersReducer from './OrdersReducer'
import WalletsReducer from './wallets';
import ServiceableRegions from './ServiceableRegion'
import Banner from './banner'
import Config from './config'

export default combineReducers({
    user: userApp,
    usersTbl: usersTblApp,
    promo_codes: PromoCodes,
    customers: CustomersReducer,
    services: ServicesReducer,
    orders: OrdersReducer,
    wallets: WalletsReducer,
    serviceable_regions: ServiceableRegions,
    banner: Banner,
    config: Config
})