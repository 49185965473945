import {
    FETCH_SERVICEABLE_REGIONS,
    FETCH_SERVICEABLE_REGIONS_SUCCESS,
    FETCH_SERVICEABLE_REGIONS_ERROR,
    UPDATE_SERVICEABLE_REGIONS,
    UPDATE_SERVICEABLE_REGIONS_SUCCESS,
    UPDATE_SERVICEABLE_REGIONS_ERROR,
    ADD_SERVICEABLE_REGIONS,
    ADD_SERVICEABLE_REGIONS_SUCCESS,
    ADD_SERVICEABLE_REGIONS_ERROR,
    DELETE_SERVICEABLE_REGIONS,
    DELETE_SERVICEABLE_REGIONS_SUCCESS,
    DELETE_SERVICEABLE_REGIONS_ERROR,
    SERVICEABLE_REGIONS_ERROR_REMOVE
} from '@redux/types'

import _ from 'lodash'

const initialState = {
    type: null,
    error: null,
    list: {},
    sortBy: 'created_at',
    sortDir: -1,
    filters: {}
};

export default (state = initialState, action) => {
    let list;
    switch (action.type) {

        case FETCH_SERVICEABLE_REGIONS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_SERVICEABLE_REGIONS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_SERVICEABLE_REGIONS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case UPDATE_SERVICEABLE_REGIONS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_SERVICEABLE_REGIONS_SUCCESS:
            list = _.cloneDeep(state.list);
            let index = _.findIndex(list.data, (item) => {
                return item._id == action.payload._id
            })
            list.data[index] = action.payload
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case UPDATE_SERVICEABLE_REGIONS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case ADD_SERVICEABLE_REGIONS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case ADD_SERVICEABLE_REGIONS_SUCCESS:
            list = _.cloneDeep(state.list);
            list.data.unshift(action.payload);
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case ADD_SERVICEABLE_REGIONS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case DELETE_SERVICEABLE_REGIONS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case DELETE_SERVICEABLE_REGIONS_SUCCESS:
            list = _.cloneDeep(state.list);
            _.remove(list.data, (item) => {
                return item._id == action.payload._id
            })

            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case DELETE_SERVICEABLE_REGIONS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case SERVICEABLE_REGIONS_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        default:
            return state;
    }
};
