import {
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_ERROR,
    UPDATE_CUSTOMERS,
    UPDATE_CUSTOMERS_SUCCESS,
    UPDATE_CUSTOMERS_ERROR,
    ADD_CUSTOMERS,
    ADD_CUSTOMERS_SUCCESS,
    ADD_CUSTOMERS_ERROR,
    DELETE_CUSTOMERS,
    DELETE_CUSTOMERS_SUCCESS,
    DELETE_CUSTOMERS_ERROR,
    CUSTOMERS_ERROR_REMOVE,
    CUSTOMERS_UPDATE_COLUMNS,
    SEND_NOTIFICATION,
    SEND_NOTIFICATION_SUCCESS,
    SEND_NOTIFICATION_ERROR,
    CUSTOMER_TIME_ZONE,
} from '@redux/types'

import _ from 'lodash'

const initialState = {
    type: null,
    error: null,
    list: {},
    sortBy: 'created_at',
    sortDir: -1,
    filters: {},
    columns: ["first_name", "last_name", "phone", "created_at", "app_version", "Orders", "email"],
    timeslots: {}
};

export default (state = initialState, action) => {
    let list;
    switch (action.type) {

        case SEND_NOTIFICATION:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case SEND_NOTIFICATION_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case FETCH_CUSTOMERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_CUSTOMERS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case UPDATE_CUSTOMERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_CUSTOMERS_SUCCESS:
            list = _.cloneDeep(state.list);
            let index = _.findIndex(list.data, (item) => {
                return item._id == action.payload._id
            })
            list.data[index] = action.payload
            return {
                ...state,
                ...action,
                list: list,
                error: null,
            }
            break;
        case UPDATE_CUSTOMERS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case ADD_CUSTOMERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case ADD_CUSTOMERS_SUCCESS:
            list = _.cloneDeep(state.list);
            list.data.unshift(action.payload);
            return {
                ...state,
                ...action,
                list: list,
                error: null,
            }
            break;
        case CUSTOMER_TIME_ZONE:
           
            return {
                ...state,
                ...action,
                timeslots: action.slot,
                
            }
            break;
        case ADD_CUSTOMERS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case DELETE_CUSTOMERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case DELETE_CUSTOMERS_SUCCESS:
            list = _.cloneDeep(state.list);
            _.remove(list.data, (item) => {
                return item._id == action.payload._id
            })

            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case DELETE_CUSTOMERS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case CUSTOMERS_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        case CUSTOMERS_UPDATE_COLUMNS:
            return {
                ...state,
                columns: action.payload
            }
        default:
            return state;
    }
};
